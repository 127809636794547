@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;

	@media (hover: hover) {
		&.buildflow:hover:not(:active):not(:focus-visible) {
			background-color: $gray-1;

			.wrapper {
				@include swatch-wrapper;
			}
		}
	}
}

.wrapper {
	@include swatch-wrapper;
}

.swatch {
	@include swatch;
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);
	position: relative;
}

.checkmark {
	width: 100%;
}

.checkmarkBlack {
	path {
		stroke: $gray-5;
	}
}

.rainbow {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50%;
	background: conic-gradient(#14c926, #00a3ff, #1400ff, #ff0000, #e6ea08, #14c926);
}

.image {
	&[data-image-label='Blue Clear'] {
		background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Clear-Swatch.png?v=1682957005');
	}
	&[data-image-label='Blue Tortoise'] {
		background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Tortoise-Swatch.png?v=1682956995');
	}
	&[data-image-label='Crystal Clear'], &[data-image-label='Clear'] {
		background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984');
	}
	&[data-image-label='Pink Clear'] {
		background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/Pink-Clear-Swatch.png?v=1682956972');
	}
	&[data-image-label='Tortoise'] {
		background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/Tortoise-Swatch.png?v=1682956826');
	}
}

.label {
	text-align: center;
}